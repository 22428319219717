import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const API_URL = "https://walrus-app-8wc7g.ondigitalocean.app";

export const fetchMedals = createAsyncThunk(
  "medals/fetchMedals",
  async (gamerId) => {
    const response = await axios.get(`${API_URL}/user/${gamerId}/medals`);
    return response.data;
  }
);

const medalsSlice = createSlice({
  name: "medals",
  initialState: {
    medals: [],
    statusMedals: "idle",
    errorMedals: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchMedals.pending, (state) => {
        state.statusMedals = "loading";
      })
      .addCase(fetchMedals.fulfilled, (state, action) => {
        state.statusMedals = "succeeded";
        state.medals = action.payload;
      })
      .addCase(fetchMedals.rejected, (state, action) => {
        state.statusMedals = "failed";
        state.errorMedals = action.errorMedals.message;
      });
  },
});

export default medalsSlice.reducer;