import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

const BASE_URL = 'https://walrus-app-8wc7g.ondigitalocean.app/item/get';

export const fetchItem = createAsyncThunk('items/fetchItem', async () => {
    const response = await fetch(BASE_URL);
    if (!response.ok) {
        throw new Error('Failed to fetch items');
    }
    const data = await response.json();
    return data;
});

const itemsSlice = createSlice({
    name: 'items',
    initialState: {
        items: [],
        statusItems: 'idle',
        errorItems: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchItem.pending, (state) => {
                state.statusItems = 'loading';
            })
            .addCase(fetchItem.fulfilled, (state, action) => {
                state.statusItems = 'succeeded';
                state.items = action.payload;
            })
            .addCase(fetchItem.rejected, (state, action) => {
                state.statusItems = 'failed';
                state.error = action.error.message;
            });
    },
});

export default itemsSlice.reducer;
