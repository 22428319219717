// src/redux/userSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
const API_URL = "https://walrus-app-8wc7g.ondigitalocean.app";

export const searchUserByEmail = createAsyncThunk(
    'user/searchByEmail',
    async (email, { rejectWithValue }) => {
        try {
            const response = await axios.get(`${API_URL}/user/findUserByEmail`, { params: { email } }
            );
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

const userSlice = createSlice({
    name: 'user',
    initialState: {
        userPass: null,
        loading: false,
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(searchUserByEmail.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(searchUserByEmail.fulfilled, (state, action) => {
                state.loading = false;
                state.userPass = action.payload;
            })
            .addCase(searchUserByEmail.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            });
    },
});

export default userSlice.reducer;
