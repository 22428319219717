import React from "react";
import "./Home.css";
import ArticlesPreview from "./ArticlesPreview/ArticlesPreview";
import Articles from "../../data/Articles.json";
import RedesSociales from "../../components/RedesSociales/RedesSociales";
import AboutUs from "../about-us/AboutUs";
import GamesComponent from "../../components/Games/Games";
import Partners from "../../components/Partners/Partners";
export default function Home() {
  let articleArray = Articles.articles;

  return (
    <>
      <article>
        <section>
          <p className="text-ashara">
            Ashara Studios is a video game studio made up by video game fans who
            <br />
            work hard on creating significant experiences for gamers.
          </p>
        </section>
        <section className="container-article">
          {articleArray.map((article, index) => (
            <ArticlesPreview
              key={"article" + index}
              articleName={article.articleName}
              coverImage={article.coverImage}
              description={article.description}
              link={article.link}
            />
          ))}
        </section>
        <hr />
        <section className="container-games">
          <h1 className="philosophy-text">Play Now</h1>
          <hr />
          <GamesComponent />
        </section>
        <hr />
        <section className="container-partners">
          <h1 className="philosophy-text">Partners</h1>
          <hr />
          <Partners />
        </section>
        <hr />
        <section className="container-heroes">
          <h1 className="philosophy-text">OUR Warriors</h1>
          <hr />
          <AboutUs />
        </section>
        <hr />
        <section className="container-socialNetwork">
          <h1 className="socialNetwork-followUs">Follow Us</h1>
          <RedesSociales />
        </section>
      </article>
    </>
  );
}
