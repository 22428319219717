import React from "react";
import "./ArticlesPreview.css";

export default function ArticlesPreview(props) {
  var article = props;
  return (
    <section>
      <a href={article.link} target="_blank" rel="external nofollow noreferrer">
        <img
          alt={article.articleName}
          src={article.coverImage}
        />
        <h2>{article.articleName}</h2>
        <p>{article.description}</p>
      </a>
    </section>
  );
}
