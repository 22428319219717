import "./Loading.css";


function Loading() {
    return (
        <div className="containerLoading">
            <img
                src="/img/logoBig.png"
                alt="backgorund loagind"
                className="backgroundLoading"
            />
            <div className="loading">
                <div className="lds-spinner">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
                <img className="gifLoading" src="/img/Yuri2.gif" alt="Yuri" />
                <h2>Loading...</h2>
            </div>
        </div>
    );
}

export default Loading;
