import React from "react";
import "./Partners.css";
import Partner from "../../data/Partners.json";

export default function Partners() {
  let partnerArray = Partner.partners;

  return (
    <div className="partner-container">
      <img
        className="partners-background"
        src="/img/Partners/logoAshara.png"
        alt="logo Ashara"
      />
      <div className="partner-container2">
        <img
          className="partners-background2"
          src="/img/Partners/degrade.png"
          alt="logo Ashara"
        />
        <div className="partner">
          {partnerArray.map((partner, index3) => (
            <a
              href={partner.link}
              target="_blank"
              rel="noreferrer"
              key={index3}
            >
              <img className="" src={partner.logoImg} alt={partner.partner} />
            </a>
          ))}
        </div>
      </div>
    </div>
  );
}
