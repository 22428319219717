import React from "react";
import "./AboutUs.css";
import heroesData from "../../data/heroes.json";
import Hero from "./hero/Hero";

export default function AboutUs() {
  return (
    <section className="Heroes-container">
      {" "}
      {heroesData.heroes.map((hero, index) => (
        <Hero
          key={index}
          index={index}
          marcoMobile={heroesData.marcoMobile}
          marco1Desktop={heroesData.marco1Desktop}
          marco2Desktop={heroesData.marco2Desktop}
          lineas={heroesData.lineas}
          marcoText={heroesData.marcoText}
          hero={hero}
        />
      ))}
    </section>
  );
}
