import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchLeaderboard } from '../../redux/leaderboardSlice';
import "./Leaderboard.css";
import Loading from "../../components/Loading/Loading";
import BarProgress from "../../components/BarProgress/BarProgress";
import utils from "../../utils/utils";

function Leaderboard() {
    const dispatch = useDispatch();
    const { status, leaderboard } = useSelector((state) => state.leaderboard);
    const [players, setPlayers] = useState(0);
    const { user } = useSelector((state) => state.auth);
    let playersArray = [
        { sand: 760, players: 10 },
        { sand: 760, players: 20 },
        { sand: 400, players: 30 },
        { sand: 400, players: 40 },
        { sand: 400, players: 50 },
        { sand: 400, players: 60 },
        { sand: 400, players: 70 },
        { sand: 400, players: 80 },
        { sand: 400, players: 90 },
        { sand: 400, players: 100 },
    ];

    function addActivePlayers(activePlayers) {
        let index = Math.floor(activePlayers / 10);
        let newArray = playersArray.map((item, idx) => {
            if (idx === index) {
                return { ...item, activePlayers: activePlayers };
            } else if (item.players < activePlayers) {
                return { ...item, activePlayers: -1 };
            } else {
                return { ...item, activePlayers: 1 };
            }
        });
        return newArray;
    }

    useEffect(() => {
        setPlayers(
            leaderboard.reduce((playerCount, gamer) => {
                return gamer.receivesSand ? playerCount + 1 : playerCount;
            }, 0)
        )
    }, [leaderboard, dispatch]);

    useEffect(() => {
        if (status === "idle") {
            dispatch(fetchLeaderboard());
        }
    }, [status, dispatch]);

    return (
        status === "loading" ? (
            <Loading />
        ) : (

            <div className="container-leaderboard">
                <div className="container-leaderboard2">
                    <section className="container-sandPool">
                        <h1 className="title-season">Sand reward pool</h1>
                        <hr />
                        <p className="txt-totalPlayer">{`Reward Players: ${players}`}</p>
                        <div className="container-barProgress">
                            <p className="info-pool">
                                1000
                                <br /> Sand Reward
                                <br /> pool
                            </p>
                            <img
                                className="initial-bar"
                                src="/img/AsharaSeason/Bar/line0.png"
                                alt="Inital bar"
                            />
                            <img
                                className="backgorund-barProgress"
                                src="/img/AsharaSeason/Bar/BG.png"
                                alt="backgorund"
                            />
                            <div className="container-barProgress2">
                                {addActivePlayers(players).map((milestone, index) => (
                                    <BarProgress
                                        key={"Hito-" + index}
                                        sand={milestone.sand}
                                        milestone={milestone.players}
                                        activePlayers={milestone.activePlayers ? milestone.activePlayers : 0}
                                    />
                                ))}
                            </div>

                            <div className="container-reward">
                                <img
                                    className="backgorund-reward"
                                    src="/img/AsharaSeason/Bar/coin2BG.png"
                                    alt="SAND 2 BG"
                                />
                                <img
                                    className="coin-reward"
                                    src="/img/AsharaSeason/Bar/coin2.png"
                                    alt="SAND 2"
                                />
                                <p>
                                    <span className="italic">TOTAL SAND!</span>
                                    <br /> 5000**
                                </p>
                            </div>
                        </div>
                        <hr />
                        <h2 className="txt-invite">Invite Your Friends to unlock more sand!</h2>
                    </section>
                    <div className="continer-row-title">
                        <h2>Rank</h2>
                        <h2>AP</h2>
                        <h2>Nickname</h2>
                        <h2>Inital Score</h2>
                        <h2>Total score multiplier</h2>
                        <h2>Completed Missions</h2>
                        <h2>Will you win some sand?</h2>
                        <h2>Amount</h2>
                    </div>
                    <hr className="continer-row-title " />
                    <section className="container-leaderboard-table">
                        {leaderboard.map((gamer, index) => (
                            <div key={"gamer" + index}>
                                <div className={`continer-row${user && user._id === gamer._idGamer ? "-user" : ""}`}>
                                    {(user && user._id === gamer._idGamer) &&
                                        <img
                                            className="user-arrow"
                                            src="/img/Leaderboard/userArrow.png"
                                            alt="user-arrow"
                                        />
                                    }
                                    {index === 0 &&
                                        <img
                                            className="img-top top1"
                                            src="/img/Leaderboard/Rank_Gold.png"
                                            alt="Rank_Gold"
                                        />
                                    }
                                    {index === 1 &&
                                        <img
                                            className="img-top top2"
                                            src="/img/Leaderboard/Rank_Silver.png"
                                            alt="Rank_Gold"
                                        />
                                    }
                                    {index === 2 &&
                                        <img
                                            className="img-top"
                                            src="/img/Leaderboard/Rank_Bronze.png"
                                            alt="Rank_Gold"
                                        />
                                    }

                                    <p className={`number${index < 3 ? '-top' : ''}`}> {index === 0 ? '1st' : index === 1 ? '2nd' : index === 2 ? '3th' : index + 1}</p>
                                    {
                                        gamer.hasAsharaPass ?
                                            <img className="" src="/img/Leaderboard/StarAsharaPass.png" alt="Start" /> :
                                            <img className="" src="/img/Leaderboard/StarAsharaPassFalse.png" alt="StartFalse" />
                                    }
                                    < p className={`txt-table${user && user._id === gamer._idGamer ? "-user" : ""}`}> {gamer.nickname}</p>
                                    <p className="txt-table">{utils.roundToThreeDecimals(gamer.totalScore)}</p>
                                    <p className="txt-table">{gamer.totalScoreMultiplier}</p>
                                    <p className="txt-table">{gamer.completedQuests}</p>
                                    <p className="txt-table">{gamer.receivesSand ? "Yes 😁" : "Not yet 😟"}</p>
                                    <p className="number">{gamer.amount}</p>
                                </div>
                                {index === 0 ? <hr className="one" /> : index === 1 ? <hr className="two" /> : index === 2 ? <hr className="tree" /> : <hr />}
                            </div>
                        ))}
                    </section>
                </div >
            </div >
        )
    );
}

export default Leaderboard;
