import React, { useEffect, useState } from "react";
import "./Inventory.css";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from 'react-router-dom';
import AsharaPasses from "./Passes/AsharaPasses";
import Medals from "./Medals/Medals";
import CoinItem from "./Coin/Coin";
import { fetchPasses } from '../../redux/nftSlice';
import { fetchMedals } from '../../redux/medalsSlice';
import { fetchInventory } from "../../redux/inventorySlice";
import Items from "./Items/Items";
import Loading from "../../components/Loading/Loading";

export default function Inventory() {
    const dispatch = useDispatch();
    const [view, setView] = useState("passes");
    const { status, asharaPass } = useSelector((state) => state.nfts);
    const { statusMedals, medals } = useSelector((state) => state.medals);
    const { statusInventory } = useSelector((state) => state.inventory);
    const { user } = useSelector((state) => state.auth);
    const navigate = useNavigate();
    const countUserHasPass = (asharaPass) => {
        return asharaPass.reduce((count, pass) => {
            if (pass.userHasPass) {
                return count + 1;
            }
            return count;
        }, 0);
    };

    useEffect(() => {
        if (user !== null & status === "idle") {
            dispatch(fetchPasses(user._id));
        }
    }, [user, status, dispatch]);

    useEffect(() => {
        if (user !== null & statusMedals === "idle") {
            dispatch(fetchMedals(user._id));
        }
    }, [user, statusMedals, dispatch]);

    useEffect(() => {
        if (user !== null & statusInventory === "idle") {
            dispatch(fetchInventory(user._id));
        }
    }, [user, statusInventory, dispatch]);

    const countUserHasMedals = (medals) => {
        return medals.reduce((count, medal) => {
            if (medal.userHasMedal) {
                return count + 1;
            }
            return count;
        }, 0);
    };

    useEffect(() => {
        if (!user) {
            navigate("/SignIn");
        }
    }, [user, navigate]);
    return (
        <section className="container-inventory">
            <h1 className="philosophy-text">Inventory</h1>
            <hr className="hr-inventory"/>
            {statusInventory === "loading" && <Loading />}
            {statusInventory === "succeeded" && (
                <section className="container-inventorydb">
                    <CoinItem />
                    <Items />
                </section>
            )}
            <div className="container-nft">
                <img
                    className="bg-btn-profile"
                    src="img/User/bg-btn-profile.png"
                    alt="fondo de botones user"
                />
                <div className="container-nft-btn">
                    <div className="container-nft-btn2">
                        <button className={`button3 ${view === "passes" && "active"}`} onClick={() => setView("passes")}>Season Pass</button>
                        <button className={`button3 ${view === "medals" && "active"}`} onClick={() => setView("medals")}>Medals</button>
                    </div>
                    <hr />
                </div>
            </div>
            {view === "passes" && (
                <div className="container-nft2">
                    <p className="txt-nfts">{countUserHasPass(asharaPass)} out of {asharaPass.length} Ashara Passes</p>
                    <AsharaPasses />
                </div>
            )}
            {view === "medals" && (
                <div className="container-nft2">
                    <p className="txt-nfts">{countUserHasMedals(medals)} out of {medals.length} Medals</p>
                    <Medals />
                </div>
            )}
        </section>

    );
};


