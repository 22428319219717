import React from "react";
import "./InputWithIcon.css";

const InputWithIcon = ({ type, value, onChange, img, autocomplete }) => {
  return (
    <div className="input-icon-container">
      <img src={img} alt="Icon" className="input-icon" />
      <input
        type={type}
        value={value}
        onChange={onChange}
        className="input-with-icon"
        autoComplete={autocomplete}
      />
    </div>
  );
};

export default InputWithIcon;
