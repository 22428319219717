import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const API_URL = "https://walrus-app-8wc7g.ondigitalocean.app";
const TOTAL_SAND = 2520;

const calculateSandAmounts = (leaderboard) => {
    const eligiblePlayers = leaderboard.filter(player => player.receivesSand === true);
    const totalMultiplierSum = eligiblePlayers.reduce((sum, player) => sum + player.totalScoreMultiplier, 0);
    if (totalMultiplierSum === 0) return leaderboard;
    const updatedLeaderboard = leaderboard.map(player => {
        if (player.receivesSand) {
            const amount = (player.totalScoreMultiplier / totalMultiplierSum) * TOTAL_SAND;
            return {
                ...player,
                amount: parseFloat(amount.toFixed(3)),
            };
        } else {
            return player;
        }
    });
    return updatedLeaderboard;
};


export const fetchLeaderboard = createAsyncThunk(
    "leaderboard/fetchLeaderboard",
    async () => {
        const response = await axios.get(`${API_URL}/leaderboard`);

        console.log(calculateSandAmounts(response.data))
        return calculateSandAmounts(response.data);
    }
);

const leaderboardSlice = createSlice({
    name: "leaderboard",
    initialState: {
        leaderboard: [],
        status: "idle",
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchLeaderboard.pending, (state) => {
                state.status = "loading";
            })
            .addCase(fetchLeaderboard.fulfilled, (state, action) => {
                state.status = "succeeded";
                state.leaderboard = action.payload;
            })
            .addCase(fetchLeaderboard.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.error.message;
            })
    },
});

export default leaderboardSlice.reducer;