// redux/store.js
import { configureStore } from "@reduxjs/toolkit";
import questsReducer from "./questsSlice";
import authReducer from "./authSlice";
import nftReducer from './nftSlice';
import medalsReducer from "./medalsSlice";
import inventoryReducer from "./inventorySlice"
import leaderboardReducer from "./leaderboardSlice"
import itemsReducer from "./itemSlice";
import userReducer from "./userSlice"
export const store = configureStore({
  reducer: {
    quests: questsReducer,
    auth: authReducer,
    nfts: nftReducer,
    medals: medalsReducer,
    inventory: inventoryReducer,
    leaderboard: leaderboardReducer,
    items: itemsReducer,
    user: userReducer
  },
});
