import React, { useState } from "react";
import "./AsharaPasses.css";
import { useSelector } from "react-redux";

export default function AsharaPasses() {
    const [hoveredIndex, setHoveredIndex] = useState(null);
    const { status, error, asharaPass } = useSelector((state) => state.nfts);

    if (status === "loading") {
        return <div>Loading...</div>;
    }

    if (status === "failed") {
        return <div>Error: {error}</div>;
    }

    return (
        <section className="container-passes">
            {asharaPass.map((pass, index) => (
                <div key={`${index}AP`}>
                    <div className="container-nft3">
                        <p>{pass.name}</p>
                        <div className="container-pass"
                            onMouseEnter={() => setHoveredIndex(`${index}AP`)}
                            onMouseLeave={() => setHoveredIndex(null)}
                        >
                            <img className="img-pass" src={pass.display_image_url} alt={pass.name} />
                            {hoveredIndex === `${index}AP` && (
                                <div className="hover-txt"><p>{pass.description}</p></div>
                            )}
                        </div>
                    </div>
                    {!pass.userHasPass &&
                        <a className="button2 btn-pass"
                            href={pass.opensea_url}
                            target={"_blank"}
                            rel="noreferrer">Buy Now!
                        </a>
                    }

                </div>
            ))
            }
        </section>
    );
}