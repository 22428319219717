import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const API_URL = "https://walrus-app-8wc7g.ondigitalocean.app";

export const fetchInventory = createAsyncThunk(
    "inventories/fetchInventory",
    async (gamerId) => {
        const response = await axios.get(`${API_URL}/inventories/gamer/${gamerId}`);
        return response.data;
    }
);

const inventorySlice = createSlice({
    name: "inventories",
    initialState: {
        inventory: [],
        statusInventory: "idle",
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchInventory.pending, (state) => {
                state.statusInventory = "loading";
            })
            .addCase(fetchInventory.fulfilled, (state, action) => {
                state.statusInventory = "succeeded";
                state.inventory = action.payload;
            })
            .addCase(fetchInventory.rejected, (state, action) => {
                state.statusInventory = "failed";
                state.error = action.error.message;
            })
    },
});

export default inventorySlice.reducer;