import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const API_URL = "https://walrus-app-8wc7g.ondigitalocean.app";

export const fetchGamerQuests = createAsyncThunk(
  "quests/fetchGamerQuests",
  async (gamerId) => {
    const response = await axios.get(`${API_URL}/gamer/${gamerId}/quests`);
    return response.data;
  }
);

export const createGamerQuest = createAsyncThunk(
  "quests/createGamerQuest",
  async (
    { questId, gamerId, participationLink },
    { rejectWithValue }
  ) => {
    try {
      const response = await axios.post(`${API_URL}/gamer_quest/create`, {
        quest: questId,
        gamer: gamerId,
        participationLink,
        questStatus: "In Review",
        score: 0,
        deliveryDate: new Date().toISOString(),
        comments: [],
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateGamerQuest = createAsyncThunk(
  "quests/updateGamerQuest",
  async ({ id, participationLink }, { rejectWithValue }) => {
    try {
      const response = await axios.put(`${API_URL}/gamer_quest/update/${id}`, {
        participationLink,
        questStatus: "In Review",
        deliveryDate: new Date().toISOString(),
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const questsSlice = createSlice({
  name: "quests",
  initialState: {
    items: [],
    status: "idle",
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchGamerQuests.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchGamerQuests.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.items = action.payload;
      })
      .addCase(fetchGamerQuests.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(createGamerQuest.pending, (state) => {
        state.status = "loading";
      })
      .addCase(createGamerQuest.fulfilled, (state, action) => {
        state.status = "succeeded";
        const index = state.items.findIndex(
          (item) => item._id === action.payload._id
        );
        if (index !== -1) {
          state.items[index] = action.payload;
        }
      })
      .addCase(createGamerQuest.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      .addCase(updateGamerQuest.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateGamerQuest.fulfilled, (state, action) => {
        state.status = "succeeded";
        const index = state.items.findIndex(
          (item) => item._id === action.payload.quest._id
        );
        if (index !== -1) {
          state.items[index] = action.payload.quest;
        }
      })
      .addCase(updateGamerQuest.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      });
  },
});

export default questsSlice.reducer;
