import React, { useState, useEffect } from "react";
import "./Header.css";
import { Link, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { logout } from "../../redux/authSlice";
import { fetchItem } from "../../redux/itemSlice";

export default function Header() {
  const { user } = useSelector((state) => state.auth);
  const statusItems = useSelector(state => state.items.statusItems);
  const dispatch = useDispatch();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const location = useLocation();

  const handleLogout = () => {
    dispatch(logout());
  };

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const closeNav = () => {
    setDropdownOpen(false);
  };

  useEffect(() => {
    if (statusItems === 'idle') {
      dispatch(fetchItem());
    }
  }, [statusItems, dispatch]);
  return (
    <header>
      <nav className="navbar">
        <div className="logo">
          <img
            className="logo-large"
            src="img/LogoImage.png"
            alt="Logo Ashara Studios"
          />
          <img
            className="logo-small"
            src="img/LogoImageSmall.png"
            alt="Logo Ashara Studios"
          />
        </div>
        <input type="checkbox" id="menu-toggle" className="menu-toggle" />
        <label htmlFor="menu-toggle" className="menu-icon">
          &#9776;
        </label>
        <ul className="menu">
          <li>
            <Link
              to="/"
              onClick={closeNav}
              className={location.pathname === "/" ? "selected" : ""}
            >
              Home
            </Link>
          </li>
          <li>
            <Link
              to="/AsharaSeason"
              onClick={closeNav}
              className={location.pathname === "/AsharaSeason" ? "selected" : ""}
            >
              Ashara Season
            </Link>
          </li>
          <li>
            <Link
              to="/Leaderboard"
              onClick={closeNav}
              className={location.pathname === "/Leaderboard" ? "selected" : ""}
            >
              Leaderboard
            </Link>
          </li>
          <li>
            <Link
              to="/Quests"
              onClick={closeNav}
              className={location.pathname === "/Quests" ? "selected" : ""}
            >
              Quests
            </Link>
          </li>
          {user ? (
            <>
              <li className="dropdown">
                <Link
                  onClick={toggleDropdown}
                  className={` dropdown-button ${(location.pathname === "/Inventory" || location.pathname === "/Profile") ? "selected" : ""}`}
                >
                  <img
                    className="nav-logo"
                    src="img/user.png"
                    alt="user"
                  />
                  {user.nickname}
                </Link>
                {dropdownOpen && (
                  <ul className="dropdown-menu">
                    <li>
                      <Link to="/Inventory" onClick={toggleDropdown}>
                        Inventory
                      </Link>
                    </li>
                    <hr />
                    <li>
                      <Link to="/Profile" onClick={toggleDropdown}>
                        Profile
                      </Link>
                    </li>
                    <hr />
                    <li>
                      <Link onClick={handleLogout}>
                        Logout
                      </Link>
                    </li>
                  </ul>
                )}
              </li>
            </>
          ) : (
            <li>
              <Link
                to="/SignIn"
                className={location.pathname === "/SignIn" ? "selected" : ""}
              >
                Sign In
              </Link>
            </li>
          )}
        </ul>
      </nav>
    </header>
  );
}
