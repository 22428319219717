import React, { useState } from 'react';
import { useSelector } from "react-redux";
import "./Items.css";

const Items = () => {
    const { inventory } = useSelector((state) => state.inventory);
    const filteredItems = inventory.filter(item => item.item.type !== "Coin");

    const [hoveredItemIndex, setHoveredItemIndex] = useState(null);

    const handleMouseEnter = (index) => {
        const timeout = setTimeout(() => {
            setHoveredItemIndex(index);
        }, 1500);
        return timeout;
    };

    const handleMouseLeave = (timeout) => {
        clearTimeout(timeout);
        setHoveredItemIndex(null);
    };

    const ItemCell = ({ item, index }) => {
        const [hoverTimeout, setHoverTimeout] = useState(null);

        const onMouseEnter = () => {
            const timeout = handleMouseEnter(index);
            setHoverTimeout(timeout);
        };

        const onMouseLeave = () => {
            handleMouseLeave(hoverTimeout);
        };

        return (
            <div
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
                style={{ position: 'relative', display: 'inline-block' }}
            >
                <img src={item.item.img} alt={item.item.name} />
                <p>{item.quantity}</p>
                {hoveredItemIndex === index && (
                    <div className='txt-item'>
                        <p className="comic-bubble">
                            {item.item.name}
                        </p>
                    </div>
                )}
            </div>
        );
    };

    return (
        <table>
            <tbody>
                {[0, 1, 2].map(rowIndex => (
                    <tr key={rowIndex}>
                        {[0, 1, 2, 3, 4, 5, 6, 7, 8].map(colIndex => {
                            const itemIndex = rowIndex * 9 + colIndex;
                            const isHovered = itemIndex === hoveredItemIndex;
                            if (itemIndex < filteredItems.length) {
                                const item = filteredItems[itemIndex];
                                return (
                                    <td
                                        key={colIndex}
                                        className={`td-item ${isHovered ? "hover2" : ''}`}
                                    >
                                        <ItemCell item={item} index={itemIndex} />
                                    </td>
                                );
                            } else {
                                return <td key={colIndex} />;
                            }
                        })}
                    </tr>
                ))}
            </tbody>
        </table>
    );
};

export default Items;
