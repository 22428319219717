import React from "react";
import "./popup.css";

export default function Popup(props) {

  return (
    <div className="modal">
      <img
        src={props.img}
        alt="hidden"
      />
    </div >
  );
}
