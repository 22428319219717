import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const API_URL = "https://walrus-app-8wc7g.ondigitalocean.app";

export const fetchPasses = createAsyncThunk(
  "nfts/fetchPasses",
  async (gamerId) => {
    const response = await axios.get(`${API_URL}/user/${gamerId}/asharapass`);
    return response.data;
  }
);

const nftSlice = createSlice({
  name: "nfts",
  initialState: {
    medals: [],
    asharaPass: [],
    status: "idle",
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchPasses.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchPasses.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.asharaPass = action.payload;
      })
      .addCase(fetchPasses.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
  },
});

export default nftSlice.reducer;