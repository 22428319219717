import React from "react";
import { useSelector } from "react-redux";
import "./Coin.css";

function CoinItem() {
    const { inventory } = useSelector((state) => state.inventory);
    const coinItem = inventory.find((inv) => inv.item.type === "Coin");
    return (
        coinItem ? (
            <div className="coin-item">
                <h1>{coinItem.item.name}</h1>
                <div>
                    <img src={coinItem.item.img} alt={coinItem.item.name} />
                    <p>{coinItem.quantity}</p>
                </div>
            </div>
        ) : (
            <p>No Coin item found.</p>
        )
    );
}

export default CoinItem;
