import React from "react";
import "./GamesPreview.css";

export default function GamesPreview(props) {
  var game = props;
  return (
    <section>
      <img alt={game.gameName} src={game.coverImage} />
      <h2>{game.gameName}</h2>
      <a
        className="button1"
        href={game.link}
        target="_blank"
        rel="external nofollow noreferrer"
      >
        Play Here
      </a>
    </section>
  );
}
