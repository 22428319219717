import React, { Component } from 'react';
import './FooterIcon.css';

class FooterIcon extends Component {
  render() {
    return (
      <section className={this.props.class + " icon-container"}>
        <img alt="" className="footer-icon footer-icon-small" src={this.props.smallIcon} />
        <img alt="" className="footer-icon footer-icon-medium" src={this.props.medIcon} />
        <img alt="" className="footer-icon footer-icon-big" src={this.props.bigIcon} />
        <img alt="" className="footer-icon footer-icon-large" src={this.props.largeIcon} />
      </section>
    );
  }
}

export default FooterIcon;