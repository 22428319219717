import React, { Component } from "react";
import "./Hero.css";

export default class Hero extends Component {
  render() {
    const { hero } = this.props;

    return (
      <div className="Hero">
        <div className="Hero-mobile-container">
          <img
            className="Hero-marco"
            src={this.props.marcoMobile}
            alt="marcoHero"
          />
          <img
            className="Hero-marco-desktop"
            src={this.props.marco1Desktop}
            alt="marcoHero"
          />
          <img
            className="Hero-marco2-desktop"
            src={this.props.marco2Desktop}
            alt="marcoHero"
          />
          <img
            className="Hero-lineas"
            src={this.props.lineas}
            alt="marcoHero"
          />
          <a href={hero.url} target={"_blank"} rel="noreferrer">
            <img className="Hero-photo" src={hero.photo} alt="photoHero" />
          </a>
          <div className="Hero-name">
            <img
              className="Hero-marco-text"
              src={this.props.marcoText}
              alt="marcoTextHero"
            />
            <p>{hero.name}</p>
          </div>
        </div>
        <p className="Hero-description">{hero.description}</p>
      </div>
    );
  }
}
