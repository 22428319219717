const utils = {}


utils.roundToThreeDecimals = function (number) {
    return parseFloat(number.toFixed(3));
}

utils.daysUntil = (dateString) => {
    const targetDate = new Date(dateString);
    const currentDate = new Date();
    const differenceInMilliseconds = targetDate - currentDate;
    const millisecondsPerDay = 24 * 60 * 60 * 1000;
    const daysUntilDate = Math.ceil(differenceInMilliseconds / millisecondsPerDay);
    return daysUntilDate;
}

utils.hoursAndMinutesUntil = (dateString) => {
    const targetDate = new Date(dateString);
    const currentDate = new Date();
    const differenceInMilliseconds = targetDate - currentDate;
    const millisecondsPerHour = 60 * 60 * 1000;
    const millisecondsPerMinute = 60 * 1000;
    const hoursUntilDate = Math.floor(differenceInMilliseconds / millisecondsPerHour);
    const minutesUntilDate = Math.floor((differenceInMilliseconds % millisecondsPerHour) / millisecondsPerMinute);
    return `${hoursUntilDate} hours and ${minutesUntilDate} minutes`;
}


export default utils