import "./App.css";
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import Home from "./views/Home/Home";
import AsharaSeason from "./views/AsharaSeason/AsharaSeason";
import Login from "./components/LogIn/LogIn";
import Quests from "./views/Quests/Quests";
import SignUp from "./components/SignUp/SignUp";
import Inventory from "./views/Inventory/Inventory"
import { Routes, Route, Navigate } from "react-router-dom";
import Profile from "./views/Profile/Profile";
import Leaderboard from "./views/Leaderboard/Leaderboard";
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { checkSessionExpiration } from './redux/authSlice';
import NewPassword from "./components/NewPassword/NewPassword";

function App() {
  const dispatch = useDispatch();
  useEffect(() => {
    checkSessionExpiration(dispatch);
  }, [dispatch]);

  return (
    <>
      <Header />
      <hr />
      <a
        href="https://discord.gg/cw47QzmZed"
        className="btn-flotante"
        target={"_blank"}
        rel="noreferrer"
      >
        <img
          className="btn-img"
          src="/img/discord-mark-white.png"
          alt="logo dicord"
        />
      </a>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/AsharaSeason" element={<AsharaSeason />} />
        <Route path="/Quests" element={<Quests />} />
        <Route path="/Profile" element={<Profile />} />
        <Route path="/SignIn" element={<Login />} />
        <Route path="/ResetPassword" element={<NewPassword />} />
        <Route path="/SignUp" element={<SignUp />} />
        <Route path="/Inventory" element={<Inventory />} />
        <Route path="/Leaderboard" element={<Leaderboard />} />
        <Route path="/" element={<Navigate to="/" />} />
      </Routes>
      <Footer />
    </>
  );
}

export default App;
