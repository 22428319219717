import React from "react";
import "./BarProgress.css";

export default function BarProgress(props) {
  var data = props;
  return (
    <div className="continer-milestone">
      {
        (props.activePlayers === -1 || !props.activePlayers) ?
          <progress
            className="bar-progress"
            id="file"
            max="100"
            value="100"
          ></progress> :
          props.activePlayers === 1 ?
            <progress
              className="bar-progress"
              id="file"
              max="100"
              value="0"
            ></progress>
            : <progress
              className="bar-progress"
              id="file"
              max="10"
              value={
                props.activePlayers % 10
              }
            ></progress>
      }
      {
        props.activePlayers === 1 ?
          <div className="continer-flagDesactive">
            <img src="/img/AsharaSeason/Bar/hito-desactivado.png" alt="Hito" />
            <p>{data.milestone}</p>
          </div>
          : (props.activePlayers === -1 || !props.activePlayers) ?
            <div className="continer-flag">
              <img src="/img/AsharaSeason/Bar/hito.png" alt="Hito" />
              <p>{data.milestone}</p>
            </div>
            : <div className="continer-flagDesactive">
              <img src="/img/AsharaSeason/Bar/hito-desactivado.png" alt="Hito" />
              <p>{data.milestone}</p>
            </div>

      }
      <div className="continer-sand">
        <img src="/img/AsharaSeason/Bar/coin1.png" alt="SAND" />
        <p>
          +{data.sand}
          <br /> <span className="italic">SAND</span>
        </p>
      </div>
    </div >
  );
}
