import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter as Router } from "react-router-dom";
import { Provider } from "react-redux";
import { store } from "./redux/store";

const resizeObserverLoopErr = /ResizeObserver loop limit exceeded/;

const ignoreResizeObserverError = (event) => {
  if (resizeObserverLoopErr.test(event.message)) {
    event.stopImmediatePropagation();
  }
};

window.addEventListener('error', ignoreResizeObserverError);
window.addEventListener('unhandledrejection', ignoreResizeObserverError);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <Router>
      <App />
    </Router>
  </Provider>
);