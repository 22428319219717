// src/components/NewPassword.js
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { searchUserByEmail } from '../../redux/userSlice';
import { updateUserProfile } from '../../redux/authSlice'
import InputWithIcon from '../InputWithIcon/InputWithIcon';
import { useNavigate } from 'react-router-dom';
import './NewPassword.css';

const NewPassword = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorPassword, setErrorPassword] = useState("")
  const [confirmPassword, setConfirmPassword] = useState("");
  const dispatch = useDispatch();
  const { loading, error, userPass } = useSelector((state) => state.user);
  const navigate = useNavigate();

  const handleSearchUser = async () => {
    try {
      await dispatch(searchUserByEmail(email)).unwrap();
      if (userPass) {
        console.log("User found:", userPass);
      }
    } catch (err) {
      console.error('Error searching user:', err);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (password === confirmPassword) {
      try {
        await dispatch(updateUserProfile({ id: userPass.id, data: { password: password } })).unwrap();
        alert("Password updated successfully!");
        navigate('/SignIn');

      } catch (err) {
        console.error('Error updating password:', err);
      }
    } else {
      setErrorPassword("Passwords do not match")
    }
  };

  return (
    <div className="container-signin">
      <div className="container-login">
        <img alt="logo Ashara" src="/img/User/logo-ashara.png" className="logo" />
        <h2>Reset Password</h2>
        <form onSubmit={handleSubmit}>

          {!userPass ? (
            <><div>
              <label>Email:</label>
              <InputWithIcon
                type="email"
                value={email}
                autocomplete="username"
                onChange={(e) => setEmail(e.target.value)}
                img="/img/User/IconUser.png"
              />
            </div>
              <button
                type="button"
                className="btn-form"
                onClick={handleSearchUser}
                disabled={loading}
              >
                {loading ? "Searching..." : "Find User"}
              </button>
            </>
          )

            : (
              <>
                <div style={{ textAlign: "center", color: "rgba(255, 213, 64, 1)" }}>
                  <label>Usser: {userPass.nickname}</label>
                  <hr />
                </div>
                <div>
                  <label>Set new password:</label>
                  <InputWithIcon
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    img="/img/User/IconPassword.png"
                    autocomplete="new-password"
                  />
                </div>
                <div>
                  <label>Confirm new password:</label>
                  <InputWithIcon
                    type="password"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    img="/img/User/IconPassword.png"
                    autocomplete="new-password"
                  />
                </div>
                <button
                  type="submit"
                  className="btn-form btn-large"
                  disabled={loading}
                >
                  {loading ? "Updating..." : "Reset Password"}
                </button>
              </>
            )}

          {error && <p className="txt-form">{error.msg}</p>}
          {errorPassword && <p className="txt-form">{errorPassword}</p>}
        </form>
      </div>
      <img className="bg-login" alt="logo Ashara" src="/img/User/logoBig.png" />
    </div>
  );
};

export default NewPassword;
