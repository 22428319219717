import React from "react";
import "./Footer.css";
import FooterIcon from "./FooterIcon/FooterIcon";
export default function Footer() {
  return (
    <footer>
      <img
        className="footer-back footer-back-small"
        src="img/Footer/bgSmall.png"
        alt="footer-back-small"
      />
      <img
        className="footer-back footer-back-medium"
        src="img/Footer/bgMedium.png"
        alt="footer-back-medium"
      />
      <img
        className="footer-back footer-back-big"
        src="/img/Footer/bgBig.png"
        alt="footer-back-big"
      />
      <img
        className="footer-back footer-back-large"
        src="/img/Footer/bgLarge.png"
        alt="footer-back-large"
      />
      <div className="footer-grid-container">
        <FooterIcon
          className="logo"
          smallIcon="img/Footer/logoSmall.png"
          medIcon="img/Footer/logoMedium.png"
          bigIcon="img/Footer/logoBig.png"
          largeIcon="img/Footer/logoBig.png"
        />
        <div className="filler"></div>
        <div className="emailme">
          <p className="text1">
            <a href="mailto:info@asharastudios.com">INFO@ASHARASTUDIOS.COM</a>
          </p>
          <i className="fas fa-envelope fa-2x" style={{ color: "#fff" }}></i>
        </div>
        <div className="callme">
          <p className="text1">
            <a href="tel:+573142114475">(+57) 314 2114475</a>
          </p>
          <i className="fas fa-phone fa-2x" style={{ color: "#fff" }}></i>
        </div>
        <div className="closing">
          <div className="privacy">
            <FooterIcon
              smallIcon="img/Footer/policySmall.png"
              medIcon="img/Footer/policyMedium.png"
              bigIcon="img/Footer/policyBig.png"
              largeIcon="img/Footer/policyLarge.png"
            />
            <a
              href="https://drive.google.com/uc?id=1plFomls8sJb4DJp_1pABriuN-wp_t92A"
              className="text2"
            >
              Privacy Policies
            </a>
          </div>
          <p className="text2">
            Copyright © Ashara Studios {new Date().getFullYear()}
          </p>
        </div>
      </div>
    </footer>
  );
}
