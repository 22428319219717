import React, { useState } from "react";
import "./Quest.css";
import { useSelector, useDispatch } from "react-redux";
import { createGamerQuest, updateGamerQuest } from "../../redux/questsSlice";
import Popup from "./popup/popup";
import Partner from "../../data/Partners.json";
import utils from "../../utils/utils";

export default function Quest(props) {
  let quest = props.quest;
  let partnerArray = Partner.partnersQuest;
  const partner = (quest.partner || quest.partner.name !== "") && partnerArray.find((p) => p.name === quest.partner.name);
  const { user } = useSelector((state) => state.auth);
  const items = useSelector(state => state.items.items);
  const dispatch = useDispatch();
  const [participationLink, setParticipationLink] = useState("");
  const [isHovered, setIsHovered] = useState(false);


  const transformDriveLink = (originalLink) => {
    const regex = /\/file\/d\/(.*?)\/view/;
    const match = originalLink.match(regex);

    if (match && match[1]) {
      return `https://lh3.googleusercontent.com/d/${match[1]}`;
    } else {
      return null;
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (user) {
      dispatch(
        createGamerQuest({
          questId: quest._id,
          gamerId: user._id,
          participationLink
        })
      );
      setParticipationLink("");
    }
  };

  const handleSubmitUpdate = (e) => {
    e.preventDefault();
    if (user) {
      dispatch(
        updateGamerQuest({ id: quest.gamerCreated._id, participationLink })
      );
    }
  };

  return (
    <article className="container-quest" onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)
      }>

      {quest.daily ?
        <>
          <h3>{quest.title}</h3>
          <div className="daily">
            <div className="continer-Daily">
              <img src="/img/Quest/IconDaily.png" alt="Icon Daily" />
              <p> Daily</p>
            </div>
          </div>
        </>
        :
        <h3>{quest.quest_number.includes('MQ') ?
          `Medal Quest ${quest.quest_number.replace(/(MQ)/gm, "").replace(/\b0+/g, '')}: ${quest.title}` :
          `Quest ${quest.quest_number.replace(/\b0+/g, '')}: ${quest.title}`}
        </h3>
      }
      {
        quest.gamerCreated ? (
          <>
            <div
              className={`container-quest2 ${quest.gamerCreated.questStatus === "In Review"
                ? "in-review"
                : quest.gamerCreated.questStatus === "Not Approved"
                  ? "not-approved"
                  : quest.gamerCreated.questStatus === "Completed"
                    ? "completed"
                    : `todo ${(!quest.aviable || utils.daysUntil(quest.endDate) > 0) && "Disabled"}`} ${quest.daily && "continer-Daily2"}`}
            >
              <div className="container-questGame">
                <a
                  href={quest.game.link}
                  target="_blank"
                  rel="external nofollow noreferrer"
                >
                  {" "}
                  <img src={quest.game.logo} alt="logo" />
                  {quest.gamerCreated.questStatus === "Not Approved" ?
                    <p> Play Again! </p> :
                    quest.gamerCreated.questStatus === "To Do" &&
                    <p>Play now!</p>
                  }
                </a>
                {partner &&
                  <div className="container-questPartner">
                    <div className="container-txtPartner">
                      <p className="txt-partner">
                        Partner
                      </p>
                    </div>
                    <a href={partner.link} target="_blank" rel="noopener noreferrer">
                      <img src={partner.logoImg} alt={partner.name} />
                      <p className="txt-partner2">{partner.name}</p>
                    </a>
                  </div>
                }
              </div>
              <div
                className={`vertical-separator ${quest.gamerCreated.questStatus === "In Review"
                  ? "vs-in-review"
                  : quest.gamerCreated.questStatus === "Not Approved"
                    ? "vs-not-approved"
                    : quest.gamerCreated.questStatus === "Completed"
                      ? "vs-completed"
                      : "vs-todo"
                  }`}
              ></div>
              <div className="container-questInfo">
                <h4>{quest.objective}</h4>
                <p
                  className={
                    quest.gamerCreated.questStatus === "Completed"
                      ? "text-completed"
                      : "text"
                  }
                >
                  {quest.tip}
                </p>
                <div className="container-deadline">
                  <img
                    className={
                      quest.gamerCreated.questStatus === "Completed"
                        ? "deadline-completed"
                        : "deadline"
                    }
                    src={
                      quest.gamerCreated.questStatus === "Completed"
                        ? "/img/Quest/IconTimeCompleted.png"
                        : "/img/Quest/IconTimeNormal.png"
                    }
                    alt="logo"
                  />
                  {quest.daily ? <p>
                    <span>The quest closes in: </span> {utils.hoursAndMinutesUntil(quest.endDate)}.
                  </p> : <p>
                    <span>Remaining time for NFT Reward: </span> {utils.daysUntil(quest.endDate) > 0 ? `${utils.daysUntil(quest.endDate)} days` : 'Not available'}.
                    <br />
                    <span>Remaining time until the end of the Season: </span> {utils.daysUntil("2024-09-02T17:00:00.000+00:00")} days.
                  </p>}
                </div>
                <div className="txt-reward">
                  <p>Reward: </p>
                  {quest.reward.Points > 0 && <>
                    <img src="/img/Quest/IconPoints.png" alt="points" />
                    <span>{quest.reward.Points} Points </span>
                  </>
                  }
                  {quest.reward.medalObj?.name !== '' &&
                    <>
                      <img
                        className="quest-medal"
                        src={quest.reward.medalObj.img}
                        alt="logo"
                      />
                      <span>&nbsp;{quest.reward.medalObj.name}
                      </span>
                    </>
                  }
                  {quest.reward.item?.name !== '' &&
                    <>
                      <img
                        className="quest-img"
                        src={items.find(item => item._id === quest.reward.item.id).img}
                        alt="Item"
                      />
                      <span>{`${quest.reward.item.amount} ${quest.reward.item.name}`}
                      </span>
                    </>
                  }
                  {quest.reward.formula && <>
                    <img src="/img/Quest/IconPoints.png" alt="points" />
                    <span>{quest.reward.formula}</span>
                    {quest.gamerCreated.questStatus === "Completed" && <>
                      <span>&nbsp;|&nbsp;</span>
                      <p>Your Score: </p>
                      <span>&nbsp; {utils.roundToThreeDecimals(quest.gamerCreated.score)} Points</span>
                    </>}
                  </>
                  }
                </div>

                {quest.gamerCreated.transaccion &&
                  <div className="txt-reward">
                    <p>Transaccion: </p>
                    <a href={quest.gamerCreated.transaccion} target="_blank" rel="noopener noreferrer">{quest.gamerCreated.transaccion}
                    </a>
                  </div>
                }
                {quest.gamerCreated.questStatus === "In Review" ? (
                  <p className="txt-in-review">{quest.gamerCreated.questStatus} </p>
                ) : quest.gamerCreated.questStatus === "Completed" ? (<>
                  {quest.reward.formula && <>
                    <form
                      onSubmit={handleSubmitUpdate}
                      className="container-completed"
                    >
                      <input
                        name="myInput"
                        type="url"
                        placeholder="Put your link here if you want to improve your score."
                        value={participationLink}
                        onChange={(e) => setParticipationLink(e.target.value)}
                        required
                      />
                      <button type="submit">Submit</button>
                      <a className="twitter-share-button"
                        href={`https://twitter.com/intent/tweet?text=🏷️AS2 - ${quest.title} 🎮 Join Ashara Season: &hashtags=AsharaSeason2,TSBBuildersChallenge,Sandfam,TheSandboxGame&url=https://discord.gg/FEpmzSfhN9`}
                        rel="noreferrer" target={"_blank"}>
                        Tweet</a>
                    </form>
                  </>
                  }
                  <p className="txt-completed">MISSION COMPLETED!</p>
                </>
                ) : (quest.gamerCreated.questStatus === "Not Approved" || utils.daysUntil(quest.endDate) > 0) ? (
                  <>
                    <p className="txt-no-appoved">
                      {quest.gamerCreated.questStatus}
                    </p>
                    <form
                      onSubmit={handleSubmitUpdate}
                      className="container-not-approved"
                    >
                      <input
                        name="myInput"
                        type="url"
                        placeholder="Put here your link"
                        value={participationLink}
                        onChange={(e) => setParticipationLink(e.target.value)}
                        required
                      />
                      <button type="submit">Submit</button>
                      <a className="twitter-share-button"
                        href={`https://twitter.com/intent/tweet?text=🏷️AS2 - ${quest.title} 🎮 Join Ashara Season: &hashtags=AsharaSeason2,TSBBuildersChallenge,Sandfam,TheSandboxGame&url=https://discord.gg/FEpmzSfhN9`}
                        rel="noreferrer" target={"_blank"}>
                        Tweet</a>
                    </form>
                    {
                      quest.gamerCreated.comments.length > 0 &&
                      <p className="txt-why-no-appoved">
                        {quest.gamerCreated.comments[0]}
                      </p>
                    }
                  </>
                ) : (
                  (utils.daysUntil(quest.endDate) > 0) ? <form onSubmit={handleSubmit} className="container-todo">
                    <input
                      name="myInput"
                      type="url"
                      placeholder="Put here your link"
                      value={participationLink}
                      onChange={(e) => setParticipationLink(e.target.value)}
                      required
                    />
                    <button type="submit">Submit</button>
                    <a className="twitter-share-button"
                      href={`https://twitter.com/intent/tweet?text=🏷️AS2 - ${quest.title} 🎮 Join Ashara Season: &hashtags=AsharaSeason2,TSBBuildersChallenge,Sandfam,TheSandboxGame&url=https://discord.gg/FEpmzSfhN9`}
                      rel="noreferrer" target={"_blank"}>
                      Tweet</a>
                  </form> :
                    <p className="txt-disabled">DISABLED!</p>
                )}
              </div>
            </div>
            {
              isHovered && quest.gamerCreated.questStatus === "To Do" && quest.aviable === true && quest.img && utils.daysUntil(quest.endDate) > 0 && (
                <Popup img={transformDriveLink(quest.img)} />
              )
            } 
          </>
        ) : (
          < div className={`container-quest2 todo ${!quest.aviable && "Disabled"}  ${quest.daily && "continer-Daily2"}`} >
            <div className="container-questGame">
              <a
                href={quest.game.link}
                target="_blank"
                rel="external nofollow noreferrer"
              >
                {" "}
                <img src={quest.game.logo} alt="logo" />
              </a>
              {partner &&
                <div className="container-questPartner">
                  <div className="container-txtPartner">
                    <p className="txt-partner">
                      Partner
                    </p>
                  </div>
                  <a href={partner.link} target="_blank" rel="noopener noreferrer">
                    <img src={partner.logoImg} alt={partner.name} />
                    <p className="txt-partner2">{partner.name}</p>
                  </a>
                </div>
              }
            </div>
            <div className="vertical-separator vs-todo"></div>
            <div className="container-questInfo">
              <h4>{quest.objective}</h4>
              <p className="text">{quest.tip}</p>
              <div className="container-deadline">
                <img
                  className="deadline"
                  src="/img/Quest/IconTimeNormal.png"
                  alt="logo"
                />
                {quest.daily ? <p>
                  <span>The quest closes in: </span> {utils.hoursAndMinutesUntil(quest.endDate)}.
                </p> : <p>
                  <span>Remaining time for NFT Reward: </span> {utils.daysUntil(quest.endDate) > 0 ? `${utils.daysUntil(quest.endDate)} days` : 'Not available'}.
                  <br />
                  <span>Remaining time until the end of the Season: </span> {utils.daysUntil("2024-09-02T17:00:00.000+00:00")} days.
                </p>}
              </div>
              <div className="txt-reward">
                <p>Reward: </p>
                {quest.reward.Points > 0 && <>
                  <img src="/img/Quest/IconPoints.png" alt="points" />
                  <span>{quest.reward.Points} Points </span>
                </>
                }
                {quest.reward.medalObj?.name !== '' &&
                  <>
                    <img
                      className="quest-medal"
                      src={quest.reward.medalObj.img}
                      alt="logo"
                    />
                    <span>&nbsp;{quest.reward.medalObj.name}
                    </span>
                  </>
                }
                {quest.reward.item?.name !== '' &&
                  <>
                    <img
                      className="quest-img"
                      src={items.find(item => item._id === quest.reward.item.id).img}
                      alt="Item"
                    />
                    <span>{`${quest.reward.item.amount} ${quest.reward.item.name}`}
                    </span>
                  </>
                }
                {quest.reward.formula && <>
                  <img src="/img/Quest/IconPoints.png" alt="points" />
                  <span>{quest.reward.formula}</span>
                </>

                }
              </div>
              {!quest.aviable &&
                <p className="txt-disabled">DISABLED!</p>
              }
            </div>
          </div>
        )
      }
    </article >
  );
}
