import React from "react";
import "./Games.css";
import Games from "../../data/Games.json";

export default function GamesComponent() {
  let gamesArray = Games.games;

  return gamesArray.map((game, index) => (
    <div className="games-con-back" key={index}>
      <img
        className="games-background"
        src={game.BackgorundImage}
        alt={game.GameName}
      />
      <div className="games">
        <img className="games-img" src={game.CoverImage1} alt={game.GameName} />
        <section>
          <h2 className="games-title">{game.GameName}</h2>
          <p className="games-type">{game.GameType}</p>
          <p className="game-description">{game.Description}</p>
          <p className="game-platform ">Platform: {game.Platform}</p>
          <div className="game-platform-coniner">
            {game.Shop.map((shop, index2) => (
              <a
                href={shop.Link}
                target="_blank"
                rel="noreferrer"
                key={"game" + index2}
                className="btn"
              >
                <img
                  className=""
                  src={shop.ImageStore}
                  alt={game.GameName + " " + shop.NameShop}
                />
              </a>
            ))}
          </div>
        </section>
      </div>
      <hr className="hr-movil" />
    </div>
  ));
}

